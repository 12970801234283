import * as actions from "./user.actions";

export type TUserAction = ReturnType<Omit<typeof actions, "ActionTypes"> extends { [key: string]: infer U } ? U : never>

export interface IUserState {
  isAuthorized: boolean,
  isAuthorizationLoading: boolean;
  user: IUser | null;
  isUserLoading: boolean;
  isAdmin: boolean;
  notifications: INotification[];
  isNotificationsLoading: boolean;
  isMobile: boolean;
  organization: IOrganization | null;
  meta: IMeta;
}

export interface IUser {
  client_nick: string;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
}

export interface IOrganization {
  official_name: string;
  name: string;
  type: OrganizationType;
  logo_url: string;
}

export enum OrganizationType {
  SERVICE = "service",
  CUSTOMER = "customer",
}

export interface IUserWithToken {
  access: string;
  refresh: string;
}

export interface INotification {
  id: string;
  sender: string;
  timestamp: number;
  message: string;
}

export interface IInvitationComplete {
  token: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  password: string;
}

export interface IMeta {
  [key: string]: {
    [key: string]: number;
  }
}
