import { FC, useCallback, useEffect, useState } from "react";

import { AutoComplete, Button, Form, Input, message, Select, Tag } from "antd";
import { useLocation } from "react-router-dom";
import { useDropzone } from "react-dropzone";

import userIcon from "data/icons/user.svg";
import emailIcon from "data/icons/email.svg";
import phoneIcon from "data/icons/phone.svg";
import phoneIconGray from "data/icons/phone-gray.svg";
import addFileIcon from "data/icons/add-file.svg";
import exclamationIcon from "data/icons/exclamation-circle.svg";
import exclamationIconRed from "data/icons/exclamation-circle-red.svg";
import infoIcon from "data/icons/info-circle.svg";
import infoIconBlue from "data/icons/info-circle-blue.svg";
import warningIcon from "data/icons/warning-circle.svg";
import emailIconGray from "data/icons/email-gray.svg";
import warningIconOrange from "data/icons/warning-circle-orange.svg";
import attachedFileIcon from "data/icons/attached-file.svg";
import closeIcon from "data/icons/close.svg";
import { formatPhoneNumber } from "services/data.service";
import { useAppDispatch, useAppSelector } from "store";
import { getUserSelector } from "store/user/user.selector";
import { ICaseEquipment, Severity } from "store/cases/cases.types";
import api from "services/api.service";
import { ICaseForOpen } from "services/interface.service";
import { Loading } from "components/common";
import { RouteLinks } from "services/router.service";
import { getCases } from "store/cases/cases.thunks";
import { useContractors } from "helpers/useContractors";
import { OrganizationType } from "store/user/user.types";

import "./CaseForm.scss";

const severities = [
  {
    value: 0,
    label: "Info",
    icon: infoIcon,
    iconActive: infoIconBlue,
    className: "custom-radio-item-info",
  },
  {
    value: 1,
    label: "Minor",
    icon: infoIcon,
    iconActive: infoIconBlue,
    className: "custom-radio-item-info",
  },
  {
    value: 2,
    label: "Major",
    icon: warningIcon,
    iconActive: warningIconOrange,
    className: "custom-radio-item-major",
  },
  {
    value: 3,
    label: "Critical",
    icon: exclamationIcon,
    iconActive: exclamationIconRed,
    className: "custom-radio-item-critical",
  },
]

const initialValues: Partial<ICaseForOpen> = {
  subject: "",
  text: "",
  serial_number: "",
  severity: Severity.INFO,
  author_email: "",
  author_phone_number: "",
  contractorId: null,
}

interface IProps {
  afterEvent: () => void;
  serial_number?: string;
  id?: number;
}

const CaseForm: FC<IProps> = ({ afterEvent, serial_number = "", id }) => {
  const [ form ] = Form.useForm();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { isAuthorized, user, organization } = useAppSelector(getUserSelector);

  const [ stateBool, forceUpdate ] = useState(false);
  const [ files, setFiles ] = useState<File[]>([]);
  const [ caseEquipment, setCaseEquipment ] = useState<ICaseEquipment | null>(null);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isCCActive, setCCActive ] = useState(false);
  const [ isCustomerActive, setCustomerActive ] = useState(false);
  const [ additionalEmails, setAdditionalEmails ] = useState<string[]>([]);
  const [ clients, setClients ] = useState<any[]>([]);
  const [ clientsCopy, setClientsCopy ] = useState<any[]>([]);
  const [ author, setAuthor ] = useState<any>(null);
  const [ contractor, setContractor ] = useState<any>(null);
  const [ serialNumberError, setSerialNumberError ] = useState("");

  const { contractors, getContractors } = useContractors(100);

  const getClients = async (search: string) => {
    try {
      const response = await api.relevantUsers(
        // @ts-ignore
        { search },
        { page: 1, rowsPerPage: 20, count: 0 },
      );

      setClients([ ...response.data.results.map((res) => ({
        id: res.id,
        value: `${ res.first_name } ${ res.last_name }`,
        label: res.first_name,
        lastName: res.last_name,
        email: res.email,
        phone_number: res.phone_number,
      })) ]);
    } catch (e: any) {
      message.error(e.response.data.error || e.error || "Something went wrong!");
    }
  }

  const getClientsCopy = async (search: string) => {
    try {
      const response = await api.relevantUsers(
        // @ts-ignore
        { search },
        { page: 1, rowsPerPage: 20, count: 0 },
      );

      setClientsCopy([ ...response.data.results.map((res) => ({
        value: res.first_name,
        label: res.first_name,
        lastName: res.last_name,
        email: res.email,
        phone_number: res.phone_number,
      })) ]);
    } catch (e: any) {
      message.error(e.response.data.error || e.error || "Something went wrong!");
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: useCallback((acceptedFiles: File[]) => {
      let result: File[] = [];
      acceptedFiles && Object.keys(acceptedFiles).forEach((label) => {
        result.push(acceptedFiles![ +label ])
      })
      setFiles([ ...files, ...result ])
    }, []),
    multiple: true,
  })

  useEffect(() => {
    getContractors("");
    getClients("");
    getClientsCopy("");

    serial_number !== "" && findEquipment(serial_number, id);
    form.setFieldValue("serial_number", serial_number);

    if (isAuthorized && organization?.type === OrganizationType.CUSTOMER && user) {
      setAuthor({
        email: user.email,
        label2: user.first_name + " " + user.last_name,
        phone_number: user.phone_number ? formatPhoneNumber(user.phone_number) : "",
        value: user.first_name + " " + user.last_name,
      });
      form.setFieldValue("author_name", user.first_name + " " + user.last_name);
      form.setFieldValue("author_email", user.email);
      form.setFieldValue("author_phone_number", formatPhoneNumber(user.phone_number));
    }
  }, [])

  const handleCompleteForm = async (values: ICaseForOpen) => {
    try {
      setIsLoading(true);

      let attachments: number[] = [];

      await files.reduce(async (memo, file) => {
        await memo;
        const response = await api.uploadFile(file);

        attachments.push(response.id)
      }, Promise.resolve());

      await api.openCase({
          ...values,
          attachments,
        },
        contractor ?? undefined,
        !contractor ? caseEquipment?.id : undefined,
        author?.id,
        );

      setIsLoading(false);
      message.success("Case was created.")
      afterCompleteEvent();
    } catch (e: any) {
      setIsLoading(false);
      message.error(e.response.data.error || e.error || "Something went wrong!");
    }
  }

  const afterCompleteEvent = () => {
    form.setFieldsValue(initialValues);
    setFiles([]);
    setCaseEquipment(null);
    setAdditionalEmails([]);

    location.pathname === RouteLinks.PROTECTED.CASES.MAIN && dispatch(getCases());
    afterEvent();
  }

  const findEquipment = async (serial_number: string, id?: number) => {
    if (serial_number !== "" && isAuthorized) {
      try {
        const response = await api.getEquipmentForCase(serial_number, id);

        setCaseEquipment(response.data);
        if (response.data) {
          if (response.data) {
            const contractorId = response.data.contract.contractor.id;
            const name = response.data.contract.contractor.name;
            const email = response.data.contract.contractor.default_email;
            const phone = response.data.contract.contractor.phone_number ? formatPhoneNumber(response.data.contract.contractor.phone_number) : "";

            setAuthor({
              contractorId,
              email: email,
              label2: name,
              phone_number: phone,
              value: name,
            });

            form.setFieldsValue({
              contractorId,
              author_name: name,
              author_email: email,
              phone_number: phone,
            });
          }
        }
        serialNumberError && setSerialNumberError("");
      } catch (e: any) {
        setAuthor(null);
        setCaseEquipment(null);
        setSerialNumberError(e.response.data.detail);
      }
    } else {
      setCaseEquipment(null);
    }
  }

  return (
    <Form
      className="case-form-new"
      name="case-form"
      layout="vertical"
      autoComplete="off"
      form={ form }
      onFinish={ handleCompleteForm }
      initialValues={ initialValues }
    >
      <h3>Complete the form</h3>

      <Form.Item
        label="Subject"
        name="subject"
        rules={ [ { required: true, message: "Please input subject!" } ] }
      >
        <Input
          className="with-count"
          showCount
          maxLength={ 150 }
          placeholder="Add subject"
        />
      </Form.Item>

      <Form.Item
        label={
          <>
            Problem Description
            <label className="add-file-label">
              <img src={ addFileIcon } alt="" style={ { marginRight: "5px" } } />
              Add file (up to 10Mb)
              <input
                type="file"
                style={ { display: "none" } }
                multiple
                onChange={ (e) => {
                  let result: File[] = [];
                  e.target.files && Object.keys(e.target.files).forEach((label) => {
                    result.push(e.target.files![ +label ])
                  })
                  setFiles([ ...files, ...result ])
                } }
              />
            </label>
          </>
        }
        name="text"
        className="form-with-button"
      >
        <div
          { ...getRootProps() }
          className={ isDragActive ? "file-dropzone" : "" }
          onClick={ () => {} }
        >
          <input { ...getInputProps() } />
          { isDragActive
            ? <p>Drop the files here...</p>
            : <Input.TextArea
              style={ { resize: "none", height: "58px" } }
            />
          }
        </div>
      </Form.Item>

      <div className="files-list">
        { files.map((file) => (
          <div key={ file.name } className="files-item">
            <img src={ attachedFileIcon } alt="" />
            { file.name }
            <div
              className="cross-file"
              onClick={ () => setFiles(files.filter((fl) => fl.name !== file.name)) }
            >
              <img src={ closeIcon } alt="" />
            </div>
          </div>
        )) }
      </div>

      <div className="serial-radio-group">
        <div
          className={ `serial-radio-group-item ${ !isCustomerActive ? "serial-radio-group-item-active" : "" }` }
          onClick={ () => setCustomerActive(false) }
        >
          Serial Number
        </div>

        <div
          className={ `serial-radio-group-item ${ isCustomerActive ? "serial-radio-group-item-active" : "" }` }
          onClick={ () => setCustomerActive(true) }
        >
          Contractor
        </div>
      </div>

      <Form.Item
        label=""
        name={ isCustomerActive ? "customer" : "serial_number" }
      >
        { !isCustomerActive ? (
          <Input
            placeholder="Add serial number"
            onChange={ (e) => findEquipment(e.target.value) }
          />
        ) : (
          <Select
            options={ contractors }
            showSearch
            onSearch={ (value) => getContractors(value) }
            filterOption={ false }
            placeholder="Choose contractor"
            notFoundContent={ null }
            onChange={ (value) => setContractor(value) }
          />
        ) }
      </Form.Item>

      { caseEquipment && !isCustomerActive && (
        <div className="case-equipment-wrapper">
          <p className="case-equipment-description">{ caseEquipment.product.description }</p>
          <p className="case-equipment-client">{ caseEquipment.customer?.latin_name }</p>
          <div className="case-equipment-dates-wrapper">
            <p className="case-equipment-dates">{ caseEquipment.date_begin } - { caseEquipment.date_end }</p>
            <Tag className={ `${ caseEquipment.is_pending ? "pending" : caseEquipment.support_type }` }>
              { caseEquipment?.sla_ci.marker }
              {/*{ getSupportTypeTitle(caseEquipment.support_type, caseEquipment.is_active, caseEquipment.is_pending) }*/}
            </Tag>
          </div>
        </div>
      ) }

      { (!caseEquipment && serialNumberError) && (
        <div className="serial-number-error">
          {serialNumberError}
        </div>
      ) }

      <Form.Item
        name="severity"
        initialValue="info"
      >
        <div className="custom-radio-group">
          { severities.map((severity) => (
            <div
              className={ `custom-radio-item ${ (
                form.getFieldValue("severity") === undefined
                  ? severity.value === 0
                  : form.getFieldValue("severity") === severity.value)
                ? severity.className : ""
              }` }
              key={ severity.value }
              onClick={ () => {
                form.setFieldsValue({ severity: severity.value })
                forceUpdate(!stateBool);
              } }
            >
              <img src={ form.getFieldValue("severity") === severity.value ? severity.iconActive : severity.icon } alt=""/>
              <p className="custom-radio-title">{ severity.label }</p>
            </div>
          )) }
        </div>
      </Form.Item>

      <div className="user-info">
        <Form.Item
          label="Contact Name"
          name="author_name"
          valuePropName="label21"
        >
          <AutoComplete
            allowClear
            autoClearSearchValue
            showSearch
            value={ author }
            onChange={ (value, option) => {
              setAuthor(value);

              if (value) {
                form.setFieldsValue({
                  // @ts-ignore
                  author_email: option?.email || "", author_phone_number: option?.phone_number || "",
                })
              }
            } }
            onSearch={ (value) => getClients(value) }
            filterOption={ false }
            placeholder="Name"
            options={ clients.map(el => ({
              id: el.id,
              value: `${ el.label } ${ el.lastName }`,
              phone_number: el.phone_number,
              email: el.email,
              label2: `${ el.label } ${ el.lastName }`,
              label: (
                <div>
                  <div style={ { color: "#0C758C" } }>
                    {el.label} {el.lastName}
                  </div>
                  <div
                    style={ {
                      fontSize: "12px",
                      lineHeight: "14px",
                      marginBottom: "2px",
                      color: "#769197",
                    } }
                  >
                    <img
                      alt=""
                      style={ { marginBottom: "-1px" } }
                      src={ phoneIconGray }
                    /> {el.phone_number}
                  </div>
                  <div
                    style={ {
                      fontSize: "12px",
                      lineHeight: "14px",
                      marginBottom: "2px",
                      color: "#769197",
                    } }
                  >
                    <img
                      alt=""
                      style={ { marginBottom: "-1px" } }
                      src={ emailIconGray }
                    /> {el.email}
                  </div>
                </div>
              ),
            })) }
            notFoundContent={ null }
            dropdownStyle={ { visibility: "visible" } }
          />
        </Form.Item>

        <Form.Item className="mg-btn-0">
          <Form.Item
            className="mg-btn-0"
            label="Email"
            name="author_email"
            rules={ [ { required: true, message: "Please input your email!" }, { type: "email", message: "Please input correct email!" } ] }
            style={ { display: "inline-block", width: "calc(50% - 10px)", marginRight: "20px" } }
          >
            <Input
              placeholder="yourname@mail.com"
              prefix={ <img src={ emailIcon } alt="" /> }
            />
          </Form.Item>

          <Form.Item
            className="mg-btn-0"
            label="Phone"
            name="author_phone_number"
            style={ { display: "inline-block", width: "calc(50% - 10px)" } }
          >
            <Input
              placeholder="+375 (00) 000-00-00"
              prefix={ <img src={ phoneIcon } alt="" style={ { marginRight: "5px" } } /> }
              onChange={ (e) => form.setFieldsValue({ author_phone_number: formatPhoneNumber(e.target.value) }) }
            />
          </Form.Item>
        </Form.Item>
      </div>

      { additionalEmails.length > 0 && (
        <Form.Item style={ { marginBottom: 0 } }>
          { additionalEmails.map((item: string, id: number) => (
            <Form.Item
              key={ id }
              className="mg-btn-0"
              label={ `Email ${ id + 2 }` }
              name={ `author_email-${ id + 2 }` }
              rules={ [ { type: "email", message: "Please input correct email!" } ] }
              style={ { display: "inline-block", width: "calc(50% - 10px)", marginRight: id % 2 === 0 ? "20px" : "0", marginTop: "15px" } }
            >
              <Input
                placeholder="yourname@mail.com"
                prefix={ <img src={ emailIcon } alt="" /> }
                value={ item }
                onChange={ (e) => setAdditionalEmails(additionalEmails.map((mail, index) => index === id ? e.target.value : mail)) }
              />
            </Form.Item>
          )) }
        </Form.Item>
      ) }

      { isCCActive ? (
        <div className="user-info cc-info">
          <Form.Item
            label="CC"
            name="email_cc"
          >
            <Select
              mode="tags"
              options={ isAuthorized ? clientsCopy.map(el => ({
                value: el.email,
                label: (
                  <div>
                    <div style={ { color: "#0C758C" } }>
                      {el.label} {el.lastName}
                    </div>
                    <div
                      style={ {
                        fontSize: "12px",
                        lineHeight: "14px",
                        marginBottom: "2px",
                        color: "#769197",
                      } }
                    >
                      <img
                        alt=""
                        style={ { marginBottom: "-1px" } }
                        src={ emailIconGray }
                      /> {el.email}
                    </div>
                  </div>
                ),
                label2: (
                  <div className="cc-list-label">
                    <div className="cc-list-label-name">
                      {el.label} {el.lastName}
                    </div>
                    <div className="cc-list-label-email">
                      {el.email}
                    </div>
                  </div>
                ),
              })) : undefined }
              optionLabelProp="label2"
              onSearch={ isAuthorized ? (value) => getClientsCopy(value) : undefined }
              filterOption={ false }
              placeholder="Name or email"
              notFoundContent={ null }
              dropdownStyle={ { visibility: isAuthorized ? "visible" : "hidden" } }
            />
          </Form.Item>
        </div>
      ) : (
        <Form.Item className="mg-btn-0">
          <Button
            className="add-email-button"
            type="text"
            onClick={ () => setCCActive(true) }
          >
            + CC
          </Button>
        </Form.Item>
      ) }

      <Form.Item className="mg-btn-0" style={ { marginTop: "15px" } }>
        <Button
          className="big-btn"
          type="primary"
          htmlType="submit"
          disabled={ isLoading || (!isCustomerActive && !author) || (isCustomerActive && !contractor) }
        >
          <Loading
            isLoading={ isLoading }
            height={ 25 }
            width={ 81 }
          >
            Open Case
          </Loading>
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CaseForm;
